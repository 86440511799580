/* Drag and Drop Flow Container */
.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

/* Layout Builder */
.layout-builder {
  display: block;
  
}

/* .canvas {
  position: relative;
  flex-grow: 1;
  border: 1px solid #8d8b8b;
} */
.canvas {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0; /* Background color of the canvas */
  display: grid;
  grid-template-columns: repeat(auto-fill, 25px); /* Adjust the grid columns as per your requirement */
  grid-template-rows: repeat(auto-fill, 25px); /* Adjust the grid rows as per your requirement */
  gap: 1px; /* Adjust the gap between grid cells */
}

.grid-cell {
  width: 100%;
  height: 100%;
  background-color: #fff; /* Color of each grid cell */
}

.canvas::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(90deg, transparent 1px, #7b7b7b3c 1px), /* Vertical lines */
    linear-gradient(0deg, transparent 1px, #7b7b7b3c 1px); /* Horizontal lines */
  background-size: 25px 25px; /* Adjust the size of the grid lines */
}


.element {
  position: absolute;
  border: 1px solid #000;
  background-color: #f0f0f0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
}

.controls {
  
  border: 1px solid #ccc;
  padding: 10px;
  
}

/* Restaurant Seating Layout */
.restaurant-table-button {
  padding: 10px 20px;
  background-color: #f3e5d7; /* Light brown color */
  border: 2px solid #8b5a2b; /* Brown color */
  border-radius: 5px;
  position: relative;
}

.restaurant-table {
  z-index: 99;
  padding: 5px;
  background-color: #f3e5d7; /* Light brown color */
  border: 2px solid #8b5a2b; /* Brown color */
  border-radius: 5px;
  position: absolute;
  text-align: center; /* Center align text */
}

.restaurant-table::before,
.restaurant-table::after,
.restaurant-table::before-top-left,
.restaurant-table::before-top-center,
.restaurant-table::before-top-right,
.restaurant-table::before-bottom-left,
.restaurant-table::before-bottom-center,
.restaurant-table::before-bottom-right {
  content: '';
  position: absolute;
  background-color: #8b5a2b; /* Brown color */
  width: 20px;
  height: 20px;
}

.restaurant-table::before,
.restaurant-table::after {
  top: 5px;
}

.restaurant-table::before-top-left,
.restaurant-table::before-top-center,
.restaurant-table::before-top-right {
  top: 5px;
}

.restaurant-table::before-bottom-left,
.restaurant-table::before-bottom-center,
.restaurant-table::before-bottom-right {
  bottom: 5px;
}

.restaurant-table::before {
  left: 5px;
}

.restaurant-table::after {
  right: 5px;
}

.restaurant-table::before-top-left,
.restaurant-table::before-bottom-left {
  left: 25%;
}

.restaurant-table::before-top-center,
.restaurant-table::before-bottom-center {
  left: 50%;
  transform: translateX(-50%);
}

.restaurant-table::before-top-right,
.restaurant-table::before-bottom-right ,

.restaurant-table:hover {
  background-color: #e0b89f; /* Lighter brown color */
}

.restaurant-table {
  transition: background-color 0.3s ease;
  margin: 5px;
}
